import React from "react";
import { message, Button } from "antd";
import { CustomButton } from "../components/adaptors/CustomButton";
import { variants } from "../utils/variants";
import LoginModal from "../components/LoginModal";
import VerifyOTPModal from "../components/VerifyOTPModal";
import AddUserDetailsModal from "../components/AddUserDetailsModal";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { helperActions } from "../utils/redux/actions";

const Playground = () => {
  const dispatch = useDispatch();
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);

  const info = () => {
    openToastMessage({
      variant: variants.positiveToastMessage,
      title: `A new OTP has been sent`,
    });
  };

  return (
    <div>
      <Button type="primary" onClick={info}>
        Display normal message
      </Button>
      <CustomButton
        title="CHECKOUT"
        variant={variants.primaryButton}
        fitContent
      />
      {/* {isMobile ? (
        <CustomDrawer
          title="Title"
          visible={openModal}
          onClose={handleCloseModal}
        >
          Hello
        </CustomDrawer>
      ) : (
        <CustomModal
          title="Title"
          centered
          width={480}
          visible={openModal}
          onClose={handleCloseModal}
        >
          Hello
        </CustomModal>
      )} */}
    </div>
  );
};

export default Playground;
