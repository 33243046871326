import React, { useState } from "react";
import { CustomModal } from "../CustomModal";
import { CustomDrawer } from "../CustomDrawer";
import { CustomButton } from "../adaptors/CustomButton";
import { variants } from "../../utils/variants";
import { isMobile } from "../../utils/helpers/global";
import AddUserDetailsContent from "./AddUserDetailsContent";
import "../sections/auth.scss";

const AddUserDetailsModal = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="auth-modal-wrapper">
      <CustomButton
        title="Open Signup Modal"
        variant={variants.primaryButton}
        onClick={handleOpenModal}
        fitContent
      />
      {isMobile() ? (
        <CustomDrawer
          visible={openModal}
          onClose={handleCloseModal}
          customClass="auth-drawer"
        >
          <AddUserDetailsContent />
        </CustomDrawer>
      ) : (
        <CustomModal
          centered
          width={480}
          visible={openModal}
          onCancel={handleCloseModal}
          customClass="auth-modal"
        >
          <AddUserDetailsContent />
        </CustomModal>
      )}
    </div>
  );
};

export default AddUserDetailsModal;
